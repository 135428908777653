.popup-menu-container
{
    border: 1px solid #00254d;
    min-width: 15rem;
    max-width: 25rem;
    background-color: #e6f2ff;
}

.popup-menu-item
{
    cursor: pointer;
    padding: .5rem;
    overflow: hidden;
    font-size: 1rem;
    font-weight: bold;
    white-space: nowrap;
    border-bottom: 1px solid #fff;
    text-overflow: ellipsis;
}

.popup-menu-item:hover
{
    background-color: #fff;
}