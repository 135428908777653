$icon-color: #0074BA;
$shadow-common: 10px 10px 5px 0px rgba(100, 100, 100, .5) !important;
$ng-select-encapsulated: false;

@import "./vendor/libs/ng-select/ng-select.scss";
@import "./vendor/libs/ngx-toastr/ngx-toastr.scss";
@import "./vendor/styles/_custom-variables/features";
@import "../node_modules/ag-grid-community/dist/styles/ag-grid.css";
@import "../node_modules/ag-grid-community/dist/styles/ag-theme-alpine.css";
@import "node_modules/bootstrap/scss/bootstrap";
@import "node_modules/bootstrap/scss/functions";
@import "node_modules/bootstrap/scss/variables";
@import "node_modules/bootstrap/scss/mixins";
@import "./app/shared/misc-components/popup-menu/popup-menu.scss";

@mixin keyframes($name) {
  @-webkit-keyframes #{$name} {
    @content;
  }
  @-moz-keyframes #{$name} {
    @content;
  }
  @keyframes #{$name} {
    @content;
  }
}

// Remove outline on desktops
@media(min-width: 720px) {
  ngb-carousel,
  .dropdown-item,
  ngb-datepicker,
  ngb-rating {
    outline: 0 !important;
  }
}

body
{
    position: relative;
}

// *******************************************************************************
// * Add class to fix select box not showing outside of a modal
.fixSelectOverlap .ng-dropdown-panel {
  position: fixed;
  top: auto !important;
  left: auto;
  width: inherit;
}

// *******************************************************************************
// * Make date picker show overmodal
ngb-datepicker {
  position: fixed !important;
  top: auto !important;
  left: auto !important;
}

.overrideDatePicker ngb-datepicker {
  position: absolute !important;
  top: auto !important;
  left: auto !important;
}

// *******************************************************************************
// * Make tools tips aligned left
.tooltip-inner {
  text-align: left !important;
}

.tooltip-inner {
  max-width: 300px !important;
}

// Fix horizontal scrollbar issue when .dropdown-menu-right element is shown
.layout-wrapper {
  overflow: hidden;
}

// *******************************************************************************
// * Page transition

.router-transitions {
  opacity: 1;
  transition: opacity .2s;

  body.app-loading & {
    opacity: .75;
  }
}

// *******************************************************************************
// * Animations

@include keyframes(ngFloatingElementAnimation) {
  0% { opacity: 0; }
  100% { opacity: 1; }
}

html {
  .tooltip,
  .popover {
    animation: ngFloatingElementAnimation .25s;
  }
  .dropdown-menu {
    animation: ngFloatingElementAnimation .1s;
  }
}

// *******************************************************************************
// * Modal animations

@include keyframes(ngModalBackdropAnimation) {
  0% { opacity: 0; }
  100% { opacity: .5; }
}
@include keyframes(ngDefaultModalAnimation) {
  0% {
    opacity: 0;
    transform: translateY(150px) scale(.8);
  }
  100% {
    opacity: 1;
    transform: translateY(0) scale(1);
  }
}
@include keyframes(ngTopModalAnimation) {
  0% {
    opacity: 0;
    transform: translateY(-100%);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}
@include keyframes(ngSlideModalAnimation) {
  0% {
    opacity: 0;
    transform: translateX(100%);
  }
  100% {
    opacity: 1;
    transform: translateX(0);
  }
}
@if $enable-rtl-support {
  @include keyframes(ngRtlSlideModalAnimation) {
    0% {
      opacity: 0;
      transform: translateX(-100%);
    }
    100% {
      opacity: 1;
      transform: translateX(0);
    }
  }
}
@include keyframes(ngFillInModalAnimation) {
  0% {
    opacity: 0;
    transform: scale(.5, .5);
  }
  100% {
    opacity: 1;
    transform: scale(1, 1);
  }
}

.ck-editor__editable_inline
{
    min-height: 10rem !important;
}

//  Fix icon if the link text is wrapped
.sidenav-link
{
    align-items: flex-start !important;
}

.sidenav-icon
{
    margin-top: .15rem !important;
}

.sidenav-vertical .sidenav-menu .sidenav-icon
{
    margin-right: .25rem !important;
}

.modal-header
{
    border-bottom: 1px solid #ccc !important;
    padding-bottom: .5rem !important;
    background-color: #fafafa !important;
}

.modal-body
{
    padding-top: 0rem !important;
}

.modal-footer
{
    border-top: 1px solid #ccc !important;
    padding-top: 0.5rem !important;
    background-color: #fafafa !important;
}

ngb-modal-backdrop.modal-backdrop {
  opacity: .5;
  animation: ngModalBackdropAnimation .15s ease-out;
}

ngb-modal-window {
  &.modal .modal-dialog {
    animation: ngDefaultModalAnimation .15s ease-out;
  }

  &.modal-top .modal-dialog {
    animation: ngTopModalAnimation .15s ease-out;
  }

  &.modal-slide .modal-dialog {
    animation: ngSlideModalAnimation .15s ease-out;
  }

  &.modal-fill-in .modal-dialog {
    animation: ngFillInModalAnimation .15s ease-out;
  }

  @include feature-rtl {
    &.modal-slide .modal-dialog {
      animation: ngRtlSlideModalAnimation .15s ease-out;
    }
  }
}

@if $enable-material-style {
  @include keyframes(ngMaterialModalBackdropAnimation) {
    0% { opacity: 0; }
    100% { opacity: .2; }
  }

  .material-style ngb-modal-backdrop.modal-backdrop {
    opacity: .2;
    animation: ngMaterialModalBackdropAnimation .15s ease-out;
  }
}

@include feature-rtl(false) {
  // Prevent horizontal scrollbar
  .dz-hidden-input {
    right: 0 !important;
    left: auto !important;
  }
}

// *******************************************************************************
// * Accordion

ngb-accordion .card-header .btn.btn-link {
  width: 100%;
  padding: 0;
  text-align: inherit;
  white-space: normal;
  border: 0;
  font-size: .894rem;
}

.ngb-accordion-with-icon .card-header .btn.btn-link {
  display: flex;
  justify-content: space-between;
}

.tooltip
{
    z-index: 1100 !important;
    position: fixed;
    pointer-events: none;
}

.text-muted
{
    font-size: .75rem !important;
    margin-bottom: 0rem;

    span
    {
        font-size: .5rem;
        margin-left: .25rem;
    }
}

.page-header
{
    display: flex;
    padding: 1rem;
    margin-top: -1.5rem !important;
    align-items: center;
    margin-left: -2rem !important;
    margin-right: -2rem !important;
    margin-bottom: 1rem !important;
    border-bottom: 1px solid #ccc;
    background-color: rgba(255, 255, 255, .5);

    h4
    {
        flex-grow: 1;
        margin-bottom: 0rem !important;
    }

    i
    {
        color: $icon-color;
        font-size: 1.25rem;
        margin-right: .75rem;
    }
}

.card
{
    border: 1px solid #ccc !important;
}

.card-header
{
    padding: 0.75rem 1.25rem;
    border-bottom: 1px solid rgba(0, 0, 0, 0.125) !important;
    margin-bottom: 0;
    background-color: rgba(0, 0, 0, 0.04) !important;
}

.card
{
    box-shadow: 2px 2px 5px 1px rgba(0, 0, 0, 0.36) !important;
}

.card-body-secondary
{
    background-color: #f5f5f5;
}

.card-buttons
{
    top: .5rem;
    right: .5rem;
    display: flex;
    position: absolute;

    div
    {
        color: transparentize($icon-color, .5);
        cursor: pointer;
        font-size: 1.25rem;
        margin-right: .25rem;
    }

    div:hover
    {
        color: $icon-color;
    }
}

.ng-value
{
    width: 100%;
}

.ng-dropdown-panel
{
    z-index: 2000 !important;
}

.form-control
{
    color: #000 !important;
}

.textarea-box
{
    border: 1px solid #ddd !important;
    padding: .5rem !important;
    min-height: 6rem !important;
    overflow-y: scroll;
}

.number
{
    text-align: right;
}

.ag-cell-focus, .ag-cell
{
    border: none !important;
}

.ag-row .ag-cell
{
    color: #000;
    font-size: .8rem;
    align-items: flex-start;
    line-height: 1.25rem;
}

.ag-grid-dialog
{
    height: 35rem;
}

.ag-cell-wrapper
{
    width: 100%;
    align-items: flex-start;
    padding-top: .25rem;
}

.ag-cell-value
{
    width: 100%;
}

.ag-grid-row-new
{
    border-color: #8f8 !important;
}

.ag-grid-row-invalid
{
    border-color: #faa !important;
}

.ag-grid-row-eod
{
  border: 1px solid #ff9800 !important;
}

.ag-side-buttons
{
    font-weight: bold;
}

.ag-side-buttons,
.ag-tool-panel-wrapper
{
    background-color: #e6f2ff !important;
}

.ag-icon .ag-icon-menu
{
    cursor: pointer !important;
}

.ag-layout-auto-height
{
    min-height: 40rem;
}

.ag-cell-wrapper .ag-cell-expandable .ag-row-group
{
    padding-top: 0rem;
}

.ag-details-row
{
    padding: 0.5rem .5rem 0.5rem 2rem !important;
    box-shadow: inset 0px 0px 8px 4px #ccc;
    background-color: #eee !important;
}

.ag-group-expanded,
.ag-group-contracted
{
    margin-top: -.5rem;
}

.sub-grid-wrapper
{
    padding: .5rem .5rem .5rem 2rem;
    box-shadow: inset 0px 0px 8px 4px #ccc;
    background-color: #eee !important;
}

.sub-grid-grid
{
    max-width: 60rem;
}

.grid-valid-message
{
    display: flex;
    font-size: .75rem;
    align-items: center;
    line-height: .75rem;
}

.grid-message-img
{
    width: 32px;
    margin: .25rem;
}

.ag-header-cell-text
{
    overflow: visible;
    white-space: normal !important;
    text-overflow: clip;
}

.ag-cell-wrapper .ag-cell-expandable .ag-row-group
{
    padding-top: 0rem !important;
}

.status-box
{
    border: 1px solid #555;
    display: inline-block;
    padding: 0rem .4rem;
    overflow: hidden;
    font-size: .75rem;
    text-align: center;
    font-family: monospace;
    font-weight: bold;
    white-space: nowrap;
    text-overflow: ellipsis;
    text-transform: uppercase;
    background-color: #fff;
}

.status-box-restricted
{
    width: 8rem;
}

.status-box-active
{
    color: #080;
    background-color: rgba(5, 134, 5, 0.25);
}

.status-box-cancelled
{
    background-color: rgba(200, 200, 200, .25);
}

.status-box-approved
{
  background-color: rgba(58, 194, 173, 0.25);
}

.status-box-new,
.status-box-cleared,
.status-box-inprogress
{
    background-color: rgba(0, 200, 0, .25);
}

.status-box-complete
{
    background-color: #cce6ff;
}

.status-box-delete,
.status-box-failed
{
    color: #f00;
    border-color: #f00;
    background-color: rgba(200, 0, 0, .25);
}

.status-box-hold,
.status-box-onhold,
.status-box-on-hold
{
    background-color: rgba(200, 0, 0, .25) !important;
}

.status-box-pending,
.status-box-underreview,
.status-box-pendingreview,
.status-box-partiallycleared
{
    background-color: rgba(255, 255, 0, .25);
}

.status-box-denied,
.status-box-inactive,
.status-box-suspended,
.status-box-ordercheck
{
    color: #f00;
    background-color: rgba(255, 255, 0, .25);
}

.ag-header-row .ag-header-cell
{
    font-size: .7rem;
}

.tab-icon
{
    font-size: 1rem;
}

.close
{
    font-size: 1.5rem;
    font-weight: bold;
}

.dialog-icon
{
    color: $icon-color;
    font-size: 1.25rem;
    margin-right: .5rem;
}

.dialog-form
{
    border-bottom: 1px solid #ddd;
    margin-bottom: 1rem;
}

.icon-delete
{
    color: rgba(230, 0, 0, .5);
}

.icon-delete:hover
{
    color: rgba(230, 0, 0, 1);
}

.material-style body .ng-select-container
{
    color: #000;
    padding: 0rem 0rem;
}

.material-style body .ng-dropdown-panel
{
    border-color: #aaa;
    border-style: solid;
    border-width: 0px 1px 1px 1px;
    background-color: #eee;
}

.material-style body .ng-select-disabled .ng-select-container
{
    background: inherit !important;
}

.customcheck
{
    cursor: pointer;
    display: block;
    position: relative;
    font-size: 1.2rem;
    user-select: none;
    padding-left: 25px;
}

.customcheck input
{
    cursor: pointer;
    opacity: 0;
    position: absolute;
}

.checkmark
{
    top: 0;
    left: 0;
    width: 20px;
    height: 20px;
    border: 1px solid #ddd;
    position: absolute;
    border-radius: 5px;
    background-color: #fff;
}

.customcheck:hover input ~ .checkmark
{
    background-color: $icon-color;
}

.customcheck input:checked ~ .checkmark
{
    border-radius: 5px;
    background-color: #fff;
}

.checkmark:after
{
    content: "";
    display: none;
    position: absolute;
}

.customcheck input:checked ~ .checkmark:after
{
    display: block;
}

.customcheck .checkmark:after
{
    top: -5px;
    left: 3px;
    width: 12px;
    height: 22px;
    border: solid $icon-color;
    transform: rotate(45deg);
    border-width: 0 4px 4px 0;
}

.icon-outline
{
    text-shadow: 0px 0px 3px #000;
}

.icon-grid
{
    font-size: 1.25rem;
    line-height: 1.5rem;
}

.icon-interactive
{
    color: transparentize($icon-color, .5);
    cursor: pointer;
}

.icon-interactive:hover
{
    color: $icon-color;
}

.table-cell-label
{
    @extend .text-muted;
    text-align: right;
    padding-right: .5rem;
    padding-bottom: .25rem;
    vertical-align: top;
}

.table-cell-data
{
    font-weight: bold;
    padding-bottom: .25rem;
    vertical-align: top;
}

.ng-dropdown-panel
{
    width: auto !important;
    z-index: 2000 !important;
}

.dropdown-menu,
.ng-dropdown-panel-items
{
    border: 1px solid #aaa !important;
    z-index: 2001 !important;
    box-shadow: $shadow-common;
    background-color: #eee !important;
}

.nav-tabs .nav-link.active,
.nav-tabs .nav-item.show .nav-link,
.nav-tabs .nav-link:not(.active),
.nav-pills .nav-link:not(.active)
{
    color: $icon-color !important;
}

.nav-tabs .nav-link
{
    padding: .75rem !important;
}

.nav-tabs .nav-link:hover
{
    border-radius: .5rem !important;
    background-color: lighten($icon-color, 50%) !important;
}

.dropdown-item
{
    cursor: pointer;
}

.eodstatus-warning
{
    border: 4px solid #ff9800 !important;
}

.product-requirement-hold
{
    color: #800000;
    width: 10rem;
    height: 2rem;
    border: 1px solid #800000;
    padding: .25rem .5rem;
    text-align: center;
    font-weight: bold;
    margin-bottom: .25rem;
    background-color: #fff;
}

.custom-file-input
{
    cursor: pointer;
}

.btn
{
    white-space: nowrap;
    border-radius: .35rem !important;
}

.btn-group .btn
{
    border-top-right-radius: 0rem !important;
    border-bottom-right-radius: 0rem !important;
}

.btn-group .dropdown-toggle-split
{
    border-top-left-radius: 0rem !important;
    border-bottom-left-radius: 0rem !important;
}

.show > .btn-primary.dropdown-toggle,
.show > .btn-outline-primary.dropdown-toggle,
.btn-primary:focus,
.btn-primary.focus,
.btn-primary:not(:disabled):not(.disabled):active,
.btn-primary:not(:disabled):not(.disabled).active,
.btn-outline-primary:not(:disabled):not(.disabled):active,
.btn-outline-primary:not(:disabled):not(.disabled).active
{
    border-color: #2186c3;
    background-color: #2186c3;
}

.btn-primary:not(:disabled):not(.disabled):active:focus,
.btn-primary:not(:disabled):not(.disabled).active:focus,
.show > .btn-primary.dropdown-toggle:focus
{
    box-shadow: inherit;
}

.good-block
{
    color: #000;
    border: 1px solid #060;
    padding: 0.5rem;
    font-size: 1rem;
    font-weight: bold;
    border-radius: 0.25rem;
    background-color: #e6ffe6;
}

.error-block
{
    color: #000;
    border: 1px solid #600;
    padding: 0.5rem;
    font-size: 1rem;
    font-weight: bold;
    border-radius: 0.25rem;
    background-color: #ffe6e6;
}

.switcher-label,
.switcher-indicator
{
    cursor: pointer !important;
}

.switcher-indicator::after
{
    border: 1px solid #ccc;
}

.faq-wrap
{
    border: 1px solid #ccc;
    padding: 1rem;
    background-color: #fff;
}

.fixed-height-helper
{
    position: relative;
}

.shadow-common
{
    box-shadow: $shadow-common;
}

fieldset
{
    border: 1px solid #ccc !important;
    padding: .5rem !important;
    border-radius: .5rem !important;

    legend
    {
        width: auto !important;
    }
}

.editor-section-break
{
    padding: .25rem 0rem;
    margin-top: 1rem;
    font-weight: bold;
    border-radius: .25rem;
    background-color: #e6f2ff;
}

.custom-file-label
{
    cursor: pointer;
    overflow: hidden;
    white-space: nowrap;
    padding-right: 4rem !important;
    text-overflow: ellipsis;
}

.select-hightlight
{
    cursor: pointer;
    background-color: #fff !important;
}

.feedback-rating-wrapper
{
    rating
    {
        color: #ffc34d;
    }

    .rate-show
    {
        width: 2rem;
        font-size: 2rem;
    }
}

.feedback-rating-wrapper-large
{
    font-size: 2.5rem;
    margin-top: -.75rem;
}